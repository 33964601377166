


































.tooltip {
    position: absolute;
}
